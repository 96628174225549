<template>
  <div class="import-error-tab">
    <PageTitle style="margin-bottom: 20px" btn="新增聯絡人" @btnClick="dialog.add = true" />

    <BaseTable :data="tableData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="姓名" prop="UserInfo.name" align="center" />
      <BaseElTableColumn label="電話號碼" prop="UserInfo.phone" align="center" />
      <BaseElTableColumn label="生日" prop="UserInfo.birthday" align="center" />
      <BaseElTableColumn label="電子郵件" prop="UserInfo.email" align="center" />
      <BaseElTableColumn label="聯絡地址" prop="UserInfo.address" align="center" />
      <BaseElTableColumn
        prop="name"
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            deleteBtn="移除"
            editBtn="檢視"
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="changePage"
    />

    <ContactMemberAdvanceSelect
      v-if="dialog.add"
      :tagOptions="tags"
      @close="dialog.add = false"
      @update="onMembersUpdate"
    />

    <DeleteDialog
      v-if="dialog.delete"
      title="警示"
      content="確定要刪除？刪除後將無法復原"
      width="40%"
      @close="dialog.delete = false"
      @delete="removeShopContactMembers"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import ContactMemberAdvanceSelect from './ContactMemberAdvanceSelect.vue'
// api
import {
  GetShopContactMembers,
  GetShopContactMembersCount,
  AddShopContactMembers,
  RemoveShopContactMembers,
} from '@/api/company/contactMenu'
import { map } from 'lodash'
import { defineComponent } from 'vue'
import { useTagStore } from '@/views/Marketing/components/useTagStore'
export default defineComponent({
  name: 'ContactMenu',
  components: { ContactMemberAdvanceSelect },
  mixins: [tableMixin],
  props: {
    companyId: String,
    contactMenuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const { tags, fetchTagsOnMounted } = useTagStore()
    fetchTagsOnMounted()
    return {
      tags,
    }
  },
  data: () => ({
    tableData: [],
  }),
  computed: {},
  async mounted () {
    this.extendDialog(['add'])
    await this.refresh()
  },
  methods: {
    async changePage () {
      await this.refresh()
    },
    async refresh () {
      this.loading = true
      await Promise.all([
        this.getShopContactMembers(),
        this.getShopContactMembersCount(),
      ])
      this.loading = false
    },
    async getShopContactMembers () {
      const [res, err] = await GetShopContactMembers({
        shopId: this.shop,
        id: this.companyId,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
      })
      if (this.showErrorMsg(err)) return
      this.tableData = res
    },
    async getShopContactMembersCount () {
      const [res, err] = await GetShopContactMembersCount({
        shopId: this.shop,
        id: this.companyId,
      })
      if (this.showErrorMsg(err)) return
      this.tableOptions.dataCount = res.count
    },
    onRowEdit (row) {
      this.$router.push({
        name: 'MemberProfile',
        params: {
          id: row.id,
        },
      })
    },
    onRowDelete (row) {
      this.selectRow = row
      this.dialog.delete = true
    },
    async removeShopContactMembers () {
      const [, err] = await RemoveShopContactMembers({
        shopId: this.shop,
        id: this.companyId,
        memberIds: [this.selectRow.id],
      })
      if (this.showErrorMsg(err)) return
      this.$message.success('移除聯絡人成功')
      await this.refresh()
    },
    async addShopContactMembers (memberIds) {
      const [, err] = await AddShopContactMembers({
        shopId: this.shop,
        id: this.companyId,
        memberIds,
      })
      if (this.showErrorMsg(err)) return
      this.$message.success('更新聯絡人成功')
      await this.refresh()
    },
    async onMembersUpdate (data) {
      console.log(data)
      await this.addShopContactMembers(map(data, 'id'))
      console.log(data)
    },
  },
})
</script>

<style lang="postcss" scoped>

</style>
