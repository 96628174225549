<template>
  <BaseElSelect
    ref="select"
    v-model="syncModel"
    :multiple="multiple"
    clearable
    reserve-keyword
    collapse-tags
    filterable
    :placeholder="placeholder || '選取會員標籤'"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
  >
    <BaseElSelectOption
      v-for="item in filterTags"
      :key="item.id"
      :style="{ 'max-width': width + 'px' }"
      :label="item.name"
      :value="getValue(item)"
    />
  </BaseElSelect>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useElementSize } from '@vueuse/core'
export default defineComponent({
  name: 'MemberTagSelect',
  props: {
    model: [Array, Object, String],
    multiple: Boolean,
    objKey: String,
    preserveNull: Boolean,
    manual: Boolean,
    data: Array,
    placeholder: String,
  },
  setup(props) {
    const select = ref(null)
    const { width } = useElementSize(select)

    const filterTags = computed(() => {
      if (props.preserveNull) {
        return props.data
      }
      return props.data.filter((item) => !item.isNullMember)
    })
    return {
      select,
      width,
      filterTags,
    }
  },
  data: () => ({
    loading: false,
    tagList: [],
  }),

  computed: {
    syncModel: {
      get() {
        return this.model
      },

      set(data) {
        this.$emit('update:model', data)
      },
    },
  },
  methods: {
    getValue(item) {
      if (this.objKey) return item[this.objKey]
      else return item
    },
  },
})
</script>

<style lang="scss"></style>
