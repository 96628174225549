<template>
  <el-dialog
    title="選取聯絡人"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-loading="loading">
      <div class="flex flex-col" style="gap: 12px">
        <BaseElInput
          v-model="nameSearch"
          suffix-icon="el-icon-search"
          placeholder="請輸入會員名稱搜尋"
          clearable
        />
        <MemberTagSelect
          :model.sync="tagSearch"
          :data="tagOptions"
          placeholder="請輸入標籤名稱搜尋"
        />
        <!-- <BaseElInput
          v-model="nameSearch"
          suffix-icon="el-icon-search"
          placeholder="請輸入標籤名稱搜尋"
          clearable
        /> -->
      </div>

      <div class="wrapper">
        <div v-if="selected.length" class="selected-wrapper">
          <Tag
            v-for="memberId in selected"
            :key="memberId"
            type="action"
            closable
            @close="removeSelect(memberId)"
          >
            {{ findSelectMember(memberId) }}
          </Tag>
        </div>
        <div class="list-wrapper">
          <BaseElCheckboxGroup v-model="selected" class="grid grid-cols-2" style="row-gap: 16px">
            <BaseElCheckbox
              v-for="member in showMemberList[tableOptions.page - 1]"
              :key="member.id"
              :label="member.id"
            >
              <p class="truncate" style="width: 220px">{{ showMemberName(member) }}</p>
            </BaseElCheckbox>
          </BaseElCheckboxGroup>
          <Pagination
            class="pagination"
            :curPage.sync="tableOptions.page"
            :pageLimit="tableOptions.pageLimit"
            :total="filterMembersCount"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end items-center" style="padding-top: 40px">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="$emit('update', composTagList), $emit('close')">確認</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import MemberTagSelect from '@/components/Select/MemberTagSelect.vue'
// api
import { GetMembers, GetMembersCount } from '@/api/member'
import { pageStartIndex } from '@/utils/table'
import { getAllDataFromApi } from '@/utils/helper'
import { mapGetters } from 'vuex'
import { find, chunk, get, cloneDeep, flattenDeep } from 'lodash'

export default {
  name: 'ContactMemberAdvanceSelect',
  components: { MemberTagSelect },
  props: {
    tagData: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 0,
    },
    returnKeys: {
      type: Array,
      default: () => [],
    },
    tagOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    tagSearch: null,

    totalMembers: [],
    tagList: [],
    searchTagList: [],
    selected: [],

    formData: {
      name: null,
      nameStatus: '',
    },

    formRules: {
      name: [noEmptyRules()],
    },

    tagCount: 0,
    toggleCreate: false,
    tableOptions: {
      page: 1,
      pageLimit: 16,
      dataCount: 0,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    // 過濾後的顯示清單
    showMemberList () {
      let list = cloneDeep(this.totalMembers)
      // 過濾會員名稱
      if (this.nameSearch) {
        list = list.filter((member) => {
          const name = get(member, 'UserInfo.name')
          if (!name) return false
          return name.toLowerCase().includes(this.nameSearch.toLowerCase())
        })
      }
      // 過濾會員標籤
      if (this.tagSearch) {
        const tagId = this.tagSearch.id
        list = list.filter(member => find(member.MTags, { id: tagId }) || find(member.SystemMTag, { id: tagId }))
      }
      if (!this.nameSearch && !this.tagSearch) return this.tagList
      return chunk(list, 16)
    },
    filterMembersCount () {
      return flattenDeep(this.showMemberList).length
    },
    composTagList () {
      const list = []
      this.selected.forEach(id => {
        const exist = find(this.totalMembers, { id })
        if (exist) list.push({ id: exist.id })
      })
      return list
    },
  },
  async mounted () {
    this.selected = cloneDeep(this.tagData)
    this.loading = true
    this.refresh()
    this.loading = false
  },
  methods: {
    removeSelect (id) {
      this.selected = this.selected.filter(member => member !== id)
    },
    async refresh () {
      await this.getMembersCount()
      await this.getAllTags()
    },
    showMemberName (member) {
      const name = member.UserInfo.name
      const phone = member.UserInfo.phone || '-'
      return `${name} (${phone})`
    },
    async getMembersCount () {
      try {
        const count = await GetMembersCount({
          shopId: this.shop,
          name: this.nameSearch || undefined,
        })
        this.tagCount = count
      } catch (err) {
        console.log('getMembersCount error')
        this.$message.error(err || err.msg)
      }
    },
    async getMembers (config) {
      try {
        return await GetMembers(config)
      } catch (err) {
        return false
      }
    },
    async getAllTags (query = undefined) {
      const max = this.tagCount
      const res = await getAllDataFromApi(
        max,
        this.getMembers,
        {
          shopId: this.shop,
          name: this.nameSearch || undefined,
          start: this.pageStartIndex,
          limit: 100,
        },
      )
      this.totalMembers = res
      this.tagList = chunk(res, 16)
    },

    disabledTag (tag) {
      if (this.max) {
        if (this.selected.length >= this.max) {
          if (this.selected.includes(tag.id)) return false
          return true
        }
      }
      return false
    },
    findSelectMember (id) {
      const exist = find(this.totalMembers, { id })
      const name = get(exist, 'UserInfo.name')
      if (name) return `${name.slice(0, 20 - 1)}...`
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply border border-gray-40 rounded-[4px] mt-[12px];
}

.selected-wrapper {
  @apply flex flex-wrap max-h-[110px] overflow-y-auto p-[8px] border-b border-gray-40 gap-[8px];
}

.list-wrapper {
  @apply p-[8px];
}

.btn {
  @apply underline text-normal font-normal flex-shrink-0 cursor-pointer;
}
.confirm {
  @apply text-action;
}

.cancel {
  @apply text-danger;
}

.create-tag-wrapper {
  @apply py-[12px];
}

.pagination {
  @apply py-[30px];
}

::v-deep .create-tag.el-input{
  @apply w-full;
}
</style>
