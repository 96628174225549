import { admin, admin2 } from '@/api/instance'

const config = (shopId) => {
  return `/${shopId}/memberConfig`
}

const baseUrl = (shopId) => {
  return `/${shopId}/member`
}

//= > 取得會員
export const GetMembersNew = async ({
  shopId,
  start,
  limit,
  name,
  phone,
  birthdayMonth,
  mTagId,
  existPhone,
  showSalesRecordTotalPaymentPriceSum = false,
}) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      name,
      phone,
      birthdayMonth,
      mTagId,
      existPhone,
      showSalesRecordTotalPaymentPriceSum,
    },
  })
}

//= > 取得會員總數
export const GetMembersCountNew = async ({
  shopId,
  name,
  phone,
  birthdayMonth,
  mTagId,
}) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
      phone,
      birthdayMonth,
      mTagId,
    },
  })
}

//= > 取得會員
export const GetMembers = async ({
  shopId,
  start,
  limit,
  name,
  phone,
  birthdayMonth,
  mTagId,
  existPhone,
  showSalesRecordTotalPaymentPriceSum = false,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      name,
      phone,
      birthdayMonth,
      mTagId,
      existPhone,
      showSalesRecordTotalPaymentPriceSum,
    },
  })

  return res.data
}

//= > 取得會員總數
export const GetMembersCount = async ({
  shopId,
  name,
  phone,
  birthdayMonth,
  mTagId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
      phone,
      birthdayMonth,
      mTagId,
    },
  })

  return res.data.count
}

//= > 查找指定會員
export const FindMember = async ({ shopId, id, phone }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
    params: {
      phone,
    },
  })

  return res.data
}

//= > 更新會員
export const UpdateMember = async ({
  shopId,
  id,
  isBlock,
  level,
  name,
  phone,
  address,
  mTags,
  birthday,
  gender,
  email,
  custom,
  levelExpiredAt,
  levelNote,
  isLevelRemainFulfilled,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      isBlock,
      level,
      name,
      phone,
      address,
      mTags,
      birthday,
      gender,
      email,
      custom,
      levelExpiredAt,
      levelNote,
      isLevelRemainFulfilled,
    },
  })

  return res.data
}

//= > 查找指定會員儲值金紀錄
export const FindDepositWalletRecord = async ({ shopId, id, start, limit }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/walletRecord`,
    params: {
      start,
      limit,
    },
  })

  return res.data
}

//= > 查找指定會員儲值金紀錄總數
export const DepositWalletRecordCount = async ({ shopId, id }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/walletRecord/count`,
  })

  return res.data.count
}

//= > 給予指定會員儲值金紀錄
export const GiveDepositWallet = async ({ shopId, id, amount, note }) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/${id}/depositWallet`,
    data: {
      amount,
      note,
    },
  })

  return res.data
}

//= > 使用指定會員儲值金
export const UseDepositWallet = async ({ shopId, id, amount, note }) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/${id}/adminUseWallet`,
    data: {
      amount,
      note,
    },
  })

  return res.data
}

export const MemberConfig = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `${config(shopId)}`,
  })

  return res
}

export const sentLineMessage = async ({
  shopId,
  id,
  useMulticast,
  messages,
}) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/${id}/sentLineMessage`,
    data: {
      useMulticast,
      messages,
    },
  })

  return res.data
}

export const GetMemberTotalPaymentPriceSum = async ({ shopId, id }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/totalPaymentPriceSum`,
  })

  return res.data
}

export const DeleteMemberAuthLine = async ({ shopId, id }) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/member/${id}/memberAuthLine`,
  })
}

export const CheckUserPhone = async ({ shopId, phone }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/registerMember/checkUserByPhone`,
    params: {
      phone,
    },
  })
}

export const CreateUserByPhone = async ({ shopId, phone, info, custom }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/registerMember/createUserByPhone`,
    data: {
      phone,
      info,
    },
  })
}
export const RegisterShopMemberByUser = async ({ shopId, userId }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/registerMember/registerShopMemberByUser`,
    data: {
      userId,
    },
  })
}

export const GetUserMoreInfoAddress = async ({ shopId, memberId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/userInfoMoreAddress`,
  })
}

export const FindOnePointWallet = async ({ shopId, memberId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/pointWallet`,
  })
}

export const QuickRegister = async ({ shopId, phone, name }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/registerMember/quickRegister`,
    data: {
      phone,
      name,
    },
  })
}
